import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

import Button from "react-bootstrap/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

import StarterEmpty from "../atoms/StarterEmpty";
import WeeklyStarter from "../molecules/WeeklyStarter";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";

import WeeklyListItem from "../molecules/WeeklyListItem";
import { ListContainer, VerticalPadding } from "../atoms";
import {
    Body,
    Footer,
    Header,
    StarterWrapper,
} from "./styles/SetWeeklyLineup.styled";
import AnalyticsSelect from "../atoms/AnalyticsSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { TableSkeleton } from "../atoms/Skeletons";
import AreYouSureModal from "../atoms/AreYouSureModal";
import LineupHeader from "../molecules/LineupHeader";
import { handleSortChange } from "../../helpers/analyticsSorter";

interface SetWeeklyLineupProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export interface WeeklyCast extends Cast {
    price: number;
    remaining: null | number;
    weekly_started: string;
    weekly_started_league: string;
    weekly_budget_started: string;
    weekly_budget_started_league: string;
    average?: number;
}

const SetWeeklyLineup: React.FC<SetWeeklyLineupProps> = ({
    open,
    setOpen,
    league,
    leagueUser,
    show,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);
    const [episode, setEpisode] = useState<number>(show.current_episode);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [cast, setCast] = useState<WeeklyCast[]>([]);
    const [startingCast, setStartingCast] = useState<WeeklyCast[]>([]);
    const [sort, setSort] = useState<string>("name");
    const [showModal, setShowModal] = useState<boolean>(false);

    const lineupRequest = useApi(
        apiRoutes.GET_WEEKLY_LINEUP(leagueUser.id.toString()),
        {
            onSuccess: (response: {
                lineup: WeeklyCast[];
                available: WeeklyCast[];
                show: Show;
            }) => {
                setCast(response.available);
                setStartingCast(response.lineup);
                setEpisode(show.current_episode);
                setLoading(false);
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );
    const saveRequest = useApi(
        apiRoutes.SAVE_WEEKLY_LINEUP(leagueUser.id.toString()),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                toast.success(response);
                setSubmitting(false);
                closePanel();
            },
            onFailure: () => setSubmitting(false),
        }
    );

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            lineupRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, show]);

    const closeWithoutSave = () => {
        document.body.style.overflow = "auto";
        if (show.locked) {
            closePanel();
        } else {
            setShowModal(true);
        }
    };

    const closePanel = () => {
        setShowModal(false);
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    const removePlayer = (id: number) => {
        const newStartingCast = startingCast.filter(
            (cast: WeeklyCast) => cast.id !== id
        );
        const newCast = cast.map((e, i) => {
            if (e.id === id) {
                const updateCast = { ...e };
                updateCast.remaining = updateCast.remaining
                    ? updateCast.remaining + 1
                    : 1;
                return updateCast;
            } else {
                return e;
            }
        });
        setCast(newCast);
        setStartingCast(newStartingCast);
    };

    const addPlayer = (player: WeeklyCast) => {
        const updateCast = { ...player };
        updateCast.remaining = updateCast.remaining
            ? updateCast.remaining - 1
            : 0;
        const newCast = cast.map((e, i) => {
            if (e.id === player.id) {
                return updateCast;
            } else {
                return e;
            }
        });
        setCast(newCast);
        setStartingCast([...startingCast, player]);
    };

    const saveLineup = () => {
        if (startingCast.length > 0) {
            setSubmitting(true);
            saveRequest.request({
                team: startingCast,
                episode,
            });
        } else {
            toast.error("Please select players");
        }
    };

    const playersPerTeam = (): number => {
        if (league.draft_type === "Weekly Redraft") {
            if (league.players_per_team >= cast.length) {
                if (cast.length === 1) {
                    return 1;
                } else {
                    return cast.length - 1;
                }
            }
        }

        return league.players_per_team;
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={closeWithoutSave}
        >
            <div className="overflow-hidden">
                <Header style={isMobile ? { height: 225 } : {}}>
                    <h5>
                        Current Team{" "}
                        <small>
                            {show.type === "week" ? "Week" : "Episode"}{" "}
                            {episode}
                        </small>
                    </h5>
                    <h6>
                        ({startingCast.length} / {playersPerTeam()})
                    </h6>
                    <StarterWrapper>
                        {startingCast.length > 0 &&
                            startingCast.map((player: WeeklyCast) => {
                                return (
                                    <WeeklyStarter
                                        player={player}
                                        key={player.id}
                                        removePlayer={removePlayer}
                                        locked={
                                            show.locked ||
                                            show.current_episode <
                                                league.episode_started
                                        }
                                    />
                                );
                            })}
                        {startingCast.length < playersPerTeam() &&
                            Array.from(
                                Array(playersPerTeam() - startingCast.length),
                                (e, i) => {
                                    return <StarterEmpty key={i} />;
                                }
                            )}
                    </StarterWrapper>
                    {isMobile && (
                        <>
                            <VerticalPadding />
                            <Button
                                disabled={
                                    submitting ||
                                    show.locked ||
                                    show.current_episode > show.episodes ||
                                    show.current_episode <
                                        league.episode_started
                                }
                                variant="success"
                                onClick={saveLineup}
                            >
                                {submitting ? (
                                    <>
                                        Saving{" "}
                                        <CircularProgress
                                            size={12}
                                            color="inherit"
                                        />
                                    </>
                                ) : (
                                    "Save Lineup"
                                )}
                            </Button>{" "}
                            <Button variant="light" onClick={closeWithoutSave}>
                                Cancel
                            </Button>
                        </>
                    )}
                </Header>
                <Body
                    style={
                        isMobile
                            ? {
                                  height: "calc(100% - 225px)",
                                  top: 225,
                                  paddingBottom: 100,
                                  borderBottomRightRadius: 20,
                              }
                            : {}
                    }
                >
                    {loading ? (
                        <>
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                        </>
                    ) : (
                        <ListContainer>
                            <LineupHeader show={show} league={league} />
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <div className="ml-3 mr-3">
                                        <AnalyticsSelect
                                            secondary="Starting (site wide)"
                                            tertiary="Starting (this league)"
                                            fourth="Average Score"
                                            value={sort}
                                            onChange={(value: string) => {
                                                setSort(value);
                                                handleSortChange(
                                                    value,
                                                    cast,
                                                    setCast,
                                                    "weeklyCast"
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            <Divider />
                            {cast.length > 0 &&
                                cast.map((player: WeeklyCast) => {
                                    return (
                                        <WeeklyListItem
                                            key={player.id}
                                            player={player}
                                            league={league}
                                            cast={cast}
                                            addPlayer={addPlayer}
                                            removePlayer={removePlayer}
                                            startingCast={startingCast}
                                            show={show}
                                        />
                                    );
                                })}
                        </ListContainer>
                    )}
                </Body>
                {!isMobile && (
                    <Footer>
                        <Button
                            disabled={
                                submitting ||
                                show.locked ||
                                show.current_episode > show.episodes ||
                                show.current_episode < league.episode_started
                            }
                            variant="success"
                            onClick={saveLineup}
                        >
                            {submitting ? (
                                <>
                                    Saving{" "}
                                    <CircularProgress
                                        size={12}
                                        color="inherit"
                                    />
                                </>
                            ) : (
                                "Save Lineup"
                            )}
                        </Button>{" "}
                        <Button variant="light" onClick={closeWithoutSave}>
                            Cancel
                        </Button>
                    </Footer>
                )}
                <AreYouSureModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    title={`Are you sure you want to exit set lineup?`}
                    body={`Your changes have not been saved.`}
                    onSuccess={() => closePanel()}
                />
            </div>
        </SlidingPanel>
    );
};

export default SetWeeklyLineup;
