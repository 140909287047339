import React, { ChangeEvent, useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { Button, Col, Row } from "react-bootstrap";

import { RootState } from "../../store";
import { imageUrl } from "../../config";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { UserModel } from "../../models/User";
import { updateUser } from "../../store/user/actions";

import SubmitButton from "../atoms/SubmitButton";
import ImageUpload from "../atoms/ImageUpload";
import Input from "../atoms/Input";
import PageTemplate from "../templates/PageTemplate";

const validationSchema = yup.object({
    email: yup
        .string()
        .nullable()
        .email("Email must be a valid email")
        .required("Email is required"),
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    img: yup.string().nullable(),
    instagram: yup.string().nullable(),
    twitter: yup.string().nullable(),
    tiktok: yup.string().nullable(),
    podcast: yup
        .string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter a valid url!"
        )
        .nullable(),
});

const EditProfile: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const initialValues = {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        img:
            user.profile_pic === null
                ? ""
                : user.profile_pic.includes("https://")
                ? user.profile_pic
                : `${imageUrl}${user.profile_pic}`,
        instagram: user.instagram,
        twitter: user.twitter,
        tiktok: user.tiktok,
        podcast: user.podcast,
    };

    const saveProfileRequest = useApi(apiRoutes.SAVE_PROFILE(), {
        onSuccess: (response: { message: string; user: UserModel }) => {
            toast.success(response.message);
            setSubmitting(false);
            dispatch(updateUser({ ...response.user, isLoggedIn: true }));
            history.push(`/profile`);
        },
        onFailure: (message: string) => {
            setSubmitting(false);
        },
    });

    const onImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue("img", files[0]);
        }
    };

    return (
        <PageTemplate header="Edit Profile">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setSubmitting(true);
                    const headers = {
                        accept: "application/json",
                        "content-type": "multipart/form-data",
                    };
                    const data = new FormData();
                    data.append("first_name", values.firstName);
                    data.append("last_name", values.lastName);
                    data.append("email", values.email);
                    data.append("img", values.img);
                    if (values.instagram)
                        data.append("instagram", values.instagram);
                    if (values.twitter) data.append("twitter", values.twitter);
                    if (values.tiktok) data.append("tiktok", values.tiktok);
                    if (values.podcast) data.append("podcast", values.podcast);
                    saveProfileRequest.request(data, headers);
                }}
            >
                {({ values, handleSubmit, isValid, setFieldValue }) => (
                    <Form
                        onSubmit={(e) => {
                            if (!isValid) {
                                toast.error("Please fix the errors");
                            }
                            handleSubmit(e);
                        }}
                    >
                        <ImageUpload
                            name="img"
                            label="Profile Image"
                            width={100}
                            image={values.img}
                            handleChange={(e) =>
                                onImageChange(e, setFieldValue)
                            }
                        />
                        <Input
                            name="email"
                            label="Email:"
                            placeholder="Email"
                        />
                        <Row>
                            <Col xs={6} md={6}>
                                <Input
                                    name={"firstName"}
                                    label="First Name:"
                                    placeholder="First Name"
                                />
                            </Col>
                            <Col xs={6} md={6}>
                                <Input
                                    name={"lastName"}
                                    label="Last Name:"
                                    placeholder="Last Name"
                                />
                            </Col>
                        </Row>
                        <h3 className="mt-3">Socials:</h3>
                        <Row className="mb-4">
                            <Col xs={6} md={6}>
                                <Input
                                    name={"instagram"}
                                    label="Instagram:"
                                    placeholder="Instagram"
                                />
                            </Col>
                            <Col xs={6} md={6}>
                                <Input
                                    name={"twitter"}
                                    label="Twitter:"
                                    placeholder="Twitter"
                                />
                            </Col>
                            <Col xs={6} md={6}>
                                <Input
                                    name={"tiktok"}
                                    label="TikTok:"
                                    placeholder="TikTok"
                                />
                            </Col>
                            <Col xs={6} md={6}>
                                <Input
                                    name={"podcast"}
                                    label="Podcast:"
                                    placeholder="Podcast"
                                />
                            </Col>
                        </Row>
                        <div className="text-center">
                            <SubmitButton
                                className="mr-2"
                                title="Save Profile"
                                submitText="Saving"
                                submitting={submitting}
                            />
                            <Button
                                variant="light"
                                onClick={() => history.push(`/profile`)}
                            >
                                Back
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default EditProfile;
