import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { determineTimezone } from "../../helpers/timezones";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import useApi from "../../hooks/useApi";
import { CastLeagueScoring } from "../../models/Cast";
import apiRoutes from "../../services/apiRoutes";
import { Show } from "../../models/Shows";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { Well } from "../atoms";
import Table from "../atoms/Table";
import EpisodeFilterSelect from "../atoms/EpisodeFilterSelect";
import StatsTableHeader from "../molecules/StatsTableHeader";
import StatsRow from "../molecules/StatsRow";
import { TableSkeleton } from "../atoms/Skeletons";

interface StatsProps {
    league: League;
    show: Show;
    leagueUser: LeaguesLeagueUser;
}

const Stats: React.FC<StatsProps> = ({ league, show, leagueUser }) => {
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [episode, setEpisode] = useState<string>("0");
    const [stats, setStats] = useState<CastLeagueScoring[]>([]);
    const [categories, setCategories] = useState<string[]>([]);

    const timezone = useMemo(
        () => determineTimezone(timezoneOffsets),
        [timezoneOffsets]
    );

    const statsRequest = useApi(
        apiRoutes.GET_CAST_STATS_BY_LEAGUE(league.id.toString()),
        {
            onSuccess: (response: {
                categories: string[];
                stats: CastLeagueScoring[];
            }) => {
                setCategories(response.categories);
                setStats(response.stats);
                setRefreshing(false);
            },
        }
    );
    const wishlistAddRequest = useApi(
        apiRoutes.ADD_TO_WISHLIST(
            leagueUser && leagueUser.id ? leagueUser.id.toString() : "0"
        ),
        {}
    );
    const wishlistRemoveRequest = useApi(
        apiRoutes.REMOVE_FROM_WISHLIST(
            leagueUser && leagueUser.id ? leagueUser.id.toString() : "0"
        ),
        {}
    );

    const removeFromWishlist = (cast_id: number) => {
        wishlistRemoveRequest.request({
            cast_id,
        });
        const updatedStats = [...stats];
        const statsIndex = updatedStats.findIndex(
            (value: CastLeagueScoring) => cast_id === value.id
        );
        updatedStats[statsIndex].wishlist = false;
        setStats(updatedStats);
    };

    const addToWishlist = (cast_id: number) => {
        wishlistAddRequest.request({
            cast_id,
        });
        const updatedStats = [...stats];
        const statsIndex = updatedStats.findIndex(
            (value: CastLeagueScoring) => cast_id === value.id
        );
        updatedStats[statsIndex].wishlist = true;
        setStats(updatedStats);
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEpisode(event.target.value);
    };

    useEffect(() => {
        statsRequest.request({ show_id: show.id, ...timezone });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show.id, timezone]);

    useEffect(() => {
        if (parseInt(episode) > 0) {
            setRefreshing(true);
            statsRequest.request({
                episode,
                show_id: show.id,
                ...timezone,
            });
        } else {
            statsRequest.request({ show_id: show.id, ...timezone });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode]);

    return (
        <Well>
            {show && (
                <>
                    <EpisodeFilterSelect
                        onChange={onChange}
                        episode={parseFloat(episode)}
                        show={show}
                    />
                    {parseFloat(episode) > 0 &&
                        parseFloat(episode) < show.current_episode && (
                            <Button
                                href={`/shows/scores/${show.slug}/${episode}?league_id=${league.id}`}
                                className="mb-3 w-100"
                            >
                                {`${
                                    show.type === "week" ? "Week" : "Episode"
                                } ${episode} Scores`}
                            </Button>
                        )}
                </>
            )}
            {refreshing && (
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            )}
            {!statsRequest.loading && !refreshing ? (
                <>
                    {parseFloat(episode) > show.current_episode ? (
                        <div className="text-center">
                            There's Currently No Stats For This Episode
                        </div>
                    ) : (
                        <>
                            {(episode === "0" &&
                                show.current_episode <
                                    league.episode_started) ||
                            (parseInt(episode) < league.episode_started &&
                                episode !== "0") ? (
                                <Alert variant="warning">
                                    This league{" "}
                                    {show.current_episode >
                                    league.episode_started
                                        ? "didn't"
                                        : "doesn't"}{" "}
                                    start until{" "}
                                    {show.type === "week" ? "week" : "episode"}{" "}
                                    {league.episode_started}
                                </Alert>
                            ) : null}
                            <Table>
                                <StatsTableHeader
                                    league={league}
                                    leagueUser={leagueUser}
                                    categories={categories}
                                />
                                <tbody>
                                    {parseFloat(episode) === 0 &&
                                        stats.length === 0 && (
                                            <tr>
                                                <td
                                                    className="text-center"
                                                    colSpan={
                                                        categories.length + 2
                                                    }
                                                >
                                                    Cast list not revealed yet.
                                                    This will be updated once
                                                    the cast is revealed
                                                </td>
                                            </tr>
                                        )}
                                    {parseFloat(episode) <=
                                        show.current_episode &&
                                        stats.map((stat: CastLeagueScoring) => (
                                            <StatsRow
                                                currentEpisode={
                                                    show.current_episode
                                                }
                                                key={stat.id}
                                                stats={stat}
                                                league={league}
                                                categories={categories}
                                                leagueUser={leagueUser}
                                                removeFromWishlist={
                                                    removeFromWishlist
                                                }
                                                addToWishlist={addToWishlist}
                                                episode={parseInt(episode)}
                                            />
                                        ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </>
            ) : (
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            )}
        </Well>
    );
};

export default Stats;
