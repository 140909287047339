import React from "react";
import { useTheme } from "styled-components";

import { Divider, Avatar } from "@mui/material";
import { Alert, Button, Row } from "react-bootstrap";

import { imageUrl } from "../../config";
import { Show } from "../../models/Shows";
import { LeagueChallengeQuestions } from "../../models/ChallengeQuestions";
import { League } from "../../models/Leagues";

import { CastAnswer } from "../organisms/AnswerChallengeQuestions";
import ScoreValue from "../atoms/ScoreValue";

interface AnswerQuestionListProps {
    questions: LeagueChallengeQuestions[];
    answers: CastAnswer[];
    league: League;
    show: Show;
    onSelect: (question: LeagueChallengeQuestions) => void;
}

const AnswerQuestionList: React.FC<AnswerQuestionListProps> = ({
    questions,
    league,
    show,
    answers,
    onSelect,
}) => {
    const theme = useTheme();

    let type: string;
    let newType: boolean = true;

    return (
        <>
            {questions &&
                questions.map((question, key) => {
                    const answer = answers.filter((a) => {
                        return (
                            a.question_id.toString() ===
                            question.question_id.toString()
                        );
                    })[0];
                    if (type !== question.type) {
                        newType = true;
                        type = question.type;
                    } else {
                        newType = false;
                    }
                    return (
                        <React.Fragment key={key}>
                            {newType && (
                                <div
                                    style={{
                                        textAlign: "center",
                                        margin: 0,
                                        backgroundColor: theme.secondary,
                                        padding: 10,
                                    }}
                                >
                                    {question.type === "Season" ? (
                                        <>
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 20,
                                                }}
                                            >
                                                Season
                                            </span>
                                            <Alert
                                                className="mt-2"
                                                variant="warning"
                                            >
                                                {show.current_episode <=
                                                league.episode_started + 1 ? (
                                                    <>
                                                        Season long questions
                                                        are locked whenever
                                                        lineups lock for{" "}
                                                        {show.type === "week"
                                                            ? "week"
                                                            : "episode"}{" "}
                                                        {league.episode_started +
                                                            1}
                                                    </>
                                                ) : (
                                                    <>
                                                        Season long questions
                                                        were locked whenever{" "}
                                                        {show.type === "week"
                                                            ? "week"
                                                            : "episode"}{" "}
                                                        {league.episode_started +
                                                            1}{" "}
                                                        aired. Your answers are
                                                        locked for the rest of
                                                        the season. You can
                                                        continue to answer{" "}
                                                        {show.type === "week"
                                                            ? "week"
                                                            : "episode"}
                                                        -to-
                                                        {show.type === "week"
                                                            ? "week"
                                                            : "episode"}{" "}
                                                        questions
                                                    </>
                                                )}
                                            </Alert>
                                        </>
                                    ) : (
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: 20,
                                            }}
                                        >{`${
                                            show.type === "week"
                                                ? "Week"
                                                : "Episode"
                                        } ${show.current_episode}`}</span>
                                    )}
                                </div>
                            )}
                            <div className="m-4 text-center">
                                <div style={{ fontSize: 18 }}>
                                    {question.description}{" "}
                                    <ScoreValue score={question.score} />
                                </div>
                                <div className="m-3" style={{ fontSize: 18 }}>
                                    {answer ? (
                                        <Row className="align-items-center justify-content-center">
                                            <Avatar
                                                style={{
                                                    height: 60,
                                                    width: 60,
                                                }}
                                                src={`${imageUrl}${answer.pic}`}
                                            />
                                            <div className="pl-2">
                                                <span
                                                    style={{
                                                        color: theme.textColor,
                                                    }}
                                                >{`${
                                                    answer.first_name &&
                                                    answer.first_name
                                                } ${
                                                    answer.last_name &&
                                                    answer.last_name
                                                }`}</span>
                                            </div>
                                        </Row>
                                    ) : (
                                        `No Answer`
                                    )}
                                </div>
                                <div className="mt-3">
                                    {!show.locked &&
                                        (question.type === "Episode" ||
                                            (question.type === "Season" &&
                                                show.current_episode <=
                                                    league.episode_started +
                                                        1)) && (
                                            <Button
                                                disabled={
                                                    show.current_episode <
                                                    league.episode_started
                                                }
                                                onClick={() =>
                                                    onSelect(question)
                                                }
                                            >
                                                {answer
                                                    ? "Change"
                                                    : "Select Player"}
                                            </Button>
                                        )}
                                </div>
                            </div>
                            <Divider />
                        </React.Fragment>
                    );
                })}
        </>
    );
};

export default AnswerQuestionList;
