import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { League } from "../../models/Leagues";
import AlertModal from "../atoms/AlertModal";
import { Show } from "../../models/Shows";

interface LeagueInfoProps {
    showInfo: boolean;
    setShowInfo: (visible: boolean) => void;
    league: League;
    show: Show;
}

const LeagueInfo: React.FC<LeagueInfoProps> = ({
    show,
    showInfo,
    setShowInfo,
    league,
}) => {
    const howToPlayList = {
        "Weekly Budget": [
            "Select a new team each week. (Set Lineup)",
            "The amount of contestants you pick each week is based on how many contestants are left",
            "You have $100 budget each week to choose contestants.",
            "$ amounts are updated based on how contestants perform throughout the season.",
            "You only get points based on the contestants you choose that week",
        ],
        "Weekly Redraft": [
            `Select ${league.players_per_team} contestants each week. (Set Lineup)`,
            "You only get points based on the contestants you choose that week.",
        ],
        Team: [
            `Set up a team of ${league.players_per_team} at the beginning of the season. (Select Team)`,
            `Start ${league.starters_per_week} out of the ${league.players_per_team} contestants on your team each week. (Set Lineup)`,
            "You only get points based on who you chose to start that week",
        ],
        "Full Draft": [
            "You draft contestants in a snake style draft until there's nobody left to choose or the commissioner of the league finishes the draft. (Join Draft)",
            "You only get points from the contestants that you drafted on your team",
        ],
        "Confidence Pool": [
            "Rank players every week based on how confident you are that they will survive each week",
            "The more confident you are in a player, the more points you get if they do survive each week",
        ],
        "Eliminated Confidence Pool": [
            "Rank players every week based on how confident you are that they will survive each week",
            "You only get points based on who is eliminated that week.",
            "The more confident you are that they will be eliminated, the more points you get if they are eliminated.",
        ],
    };

    return (
        <AlertModal
            showModal={showInfo}
            setShowModal={setShowInfo}
            title={`${league.draft_type} - How to Play`}
            body={
                <div className="pl-1 pr-1 pb-3">
                    {howToPlayList[league.draft_type]?.map(
                        (line: string, index: number) => (
                            <li key={index}>{line}</li>
                        )
                    )}
                    {league.draft_type === "Full Draft" &&
                        league.allow_trading && (
                            <li>
                                You are able to trade contestants between teams.
                                (Propose Trade)
                            </li>
                        )}
                    {(league.draft_type === "Weekly Redraft" ||
                        league.draft_type === "Weekly Budget") &&
                        league.player_limit && (
                            <li>{`You can only choose a contestant ${league.player_limit} times during the season.`}</li>
                        )}
                    {league.draft_type === "Team" &&
                        league.rookie_limit !== null &&
                        league.rookie_limit !== undefined &&
                        league.rookie_limit !== 0 && (
                            <li>{`You must choose ${league.rookie_limit} rookie(s) on your team`}</li>
                        )}
                    {league.draft_type === "Team" &&
                        league.male_limit !== null &&
                        league.male_limit !== undefined &&
                        league.male_limit !== 0 && (
                            <li>{`You must choose ${league.male_limit} male(s) on your team`}</li>
                        )}
                    {league.draft_type === "Team" &&
                        league.female_limit !== null &&
                        league.female_limit !== undefined &&
                        league.female_limit !== 0 && (
                            <li>{`You must choose ${league.female_limit} female(s) on your team`}</li>
                        )}
                    {league.draft_type === "Team" && league.swaps && (
                        <li>{`You are allowed ${league.swaps} player swap(s) for the season`}</li>
                    )}
                    {league.draft_type === "Team" &&
                    league.last_swap_ep &&
                    league.last_swap_ep > 0 ? (
                        <li>
                            {`The last time to make swaps is before ${
                                show.type === "week" ? "week" : "episode"
                            } ${league.last_swap_ep} lineups lock`}
                        </li>
                    ) : (
                        <></>
                    )}
                    {league.draft_type !== "Full Draft" && (
                        <li>
                            <CheckCircleIcon color="success" />
                            {` User set their lineup for the upcoming ${
                                show.type === "week" ? "week" : "episode"
                            }`}
                        </li>
                    )}
                </div>
            }
        />
    );
};

export default LeagueInfo;
