import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "react-bootstrap/Alert";

import { imageUrl } from "../../config";
import { colors } from "../../config/colors";
import { Show } from "../../models/Shows";
import { determineTimezone } from "../../helpers/timezones";
import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import {
    Leaderboard,
    LeaderboardList,
    LeaderboardPagination,
} from "../../models/Leaderboard";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import { Well } from "../atoms";
import Table from "../atoms/Table";
import { TableSkeleton } from "../atoms/Skeletons";
import EpisodeFilterSelect from "../atoms/EpisodeFilterSelect";
import AreYouSureModal from "../atoms/AreYouSureModal";
import ProfileOverlay from "../atoms/ProfileOverlay";
import ViewProfile from "../molecules/ViewProfile";

interface OverviewProps {
    leagueId: string;
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    onDeleteTeam: () => void;
}

const PAGE_SIZE = 200;

const Overview: React.FC<OverviewProps> = ({
    leagueId,
    league,
    leagueUser,
    show,
    onDeleteTeam,
}) => {
    const history = useHistory();
    const theme = useTheme();
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );

    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [leaderboard, setLeaderboard] = useState<LeaderboardList | []>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [episode, setEpisode] = useState<string>("0");
    const [selectedTeam, setSelectedTeam] = useState<Leaderboard>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const [profileId, setProfileId] = useState<number>(0);

    const leaderboardRequest = useApi(
        apiRoutes.GET_LEADERBOARD_BY_LEAGUE(leagueId),
        {
            onSuccess: (response: LeaderboardPagination) => {
                setLeaderboard((prev) => [...prev, ...response.leaderboard]);
                setHasMore(response.has_more);
                setPage((prev) => prev + 1);
                setLoaded(true);
                setLoading(false);
            },
        }
    );

    const deleteTeamRequest = useApi(
        apiRoutes.DELETE_TEAM(selectedTeam ? selectedTeam.id.toString() : "0"),
        {
            onSuccess: (response: string) => {
                toast.success(response);
                setDeleting(false);
                setShowDeleteModal(false);
                onDeleteTeam();
            },
            onFailure: () => {
                setDeleting(false);
            },
            responseKey: "message",
        }
    );

    useEffect(() => {
        setLoading(true);
        setLeaderboard([]);
        const timezones = determineTimezone(timezoneOffsets);
        if (episode === "0") {
            leaderboardRequest.request({
                limit: PAGE_SIZE,
                show_id: show.id,
                ...timezones,
            });
        } else {
            setPage(1);
            setLoaded(false);
            leaderboardRequest.request({
                show_id: show.id,
                episode: episode,
                page: 1,
                limit: PAGE_SIZE,
                ...timezones,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId, show.id, timezoneOffsets, episode]);

    const fetchNextOverviewData = () => {
        const timezones = determineTimezone(timezoneOffsets);
        leaderboardRequest.request({
            page: page,
            limit: PAGE_SIZE,
            show_id: show.id,
            episode: episode,
            ...timezones,
        });
    };

    const handleDeleteTeam = () => {
        setDeleting(true);
        deleteTeamRequest.request();
    };

    const renderTableBody = useMemo(() => {
        if (leaderboard.length === 0 && episode === "0" && loaded) {
            return (
                <tr>
                    <td colSpan={4} className="text-center">
                        There are no players currently in this league. Be the
                        first one to join!
                    </td>
                </tr>
            );
        }

        if (episode !== "0" && leaderboard.length <= 0 && loaded) {
            return (
                <tr
                    style={{
                        color: theme.textColor,
                    }}
                    className="mt-4 text-center"
                >
                    <td
                        colSpan={
                            leagueUser &&
                            leagueUser.commissioner &&
                            league.active
                                ? 4
                                : 3
                        }
                    >
                        No Scores for{" "}
                        {show.type === "week" ? "Week" : "Episode"} {episode}
                    </td>
                </tr>
            );
        }

        return leaderboard.map((team: Leaderboard, key: number) => {
            const profilePic = team.profile_pic?.includes("https")
                ? team.profile_pic
                : `${imageUrl}${team.profile_pic}`;
            if (team.rank === undefined && episode === "0") {
                const morePoints = leaderboard.filter((lead: Leaderboard) => {
                    return lead.points > team.points;
                });
                team.rank = morePoints.length + 1;
            }
            const rankColor =
                team.rank === 1
                    ? colors.gold
                    : team.rank === 2
                    ? colors.silver
                    : team.rank === 3
                    ? colors.bronze
                    : colors.white;
            return (
                <tr
                    key={team.id}
                    onClick={() => history.push(`/myleagues/team/${team.id}`)}
                    className={leagueUser?.id === team.id ? "selected" : ""}
                >
                    <td>{team.rank ?? key + 1}</td>
                    <td>
                        <div className="d-flex align-items-center">
                            <ProfileOverlay
                                img={profilePic}
                                showProfile={(visible: boolean) => {
                                    setShowProfile(visible);
                                    setProfileId(team.user_id);
                                }}
                                setLineup={team.set_lineup}
                            />
                            <div>
                                <Link to={`/myleagues/team/${team.id}`}>
                                    {team.team_name}
                                </Link>
                                <div>
                                    {team.first_name}{" "}
                                    {team.last_name?.charAt(0)}
                                    {team.commissioner && (
                                        <LocalPoliceIcon
                                            className="ml-1"
                                            color="primary"
                                        />
                                    )}
                                    {!league.active &&
                                    team.rank > 0 &&
                                    team.rank < 4 ? (
                                        <FontAwesomeIcon
                                            className="ml-1"
                                            icon={faTrophy}
                                            color={rankColor}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="text-right pr-5">
                        {Number(team.points).toFixed(0)}
                    </td>
                    {leagueUser?.commissioner && league.active && (
                        <td className="text-right">
                            <DeleteIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedTeam(team);
                                    setShowDeleteModal(true);
                                }}
                            />
                        </td>
                    )}
                </tr>
            );
        });
    }, [
        leaderboard,
        episode,
        history,
        leagueUser,
        league,
        loaded,
        theme,
        show,
    ]);

    return (
        <Well id="scrollableDivOverview">
            <EpisodeFilterSelect
                onChange={(e) => setEpisode(e.target.value)}
                episode={parseFloat(episode)}
                show={show}
            />
            {(episode === "0" &&
                show.current_episode < league.episode_started) ||
            (parseInt(episode) < league.episode_started && episode !== "0") ? (
                <Alert variant="warning">
                    This league{" "}
                    {show.current_episode > league.episode_started
                        ? "didn't"
                        : "doesn't"}{" "}
                    start until {show.type === "week" ? "week" : "episode"}{" "}
                    {league.episode_started}
                </Alert>
            ) : null}
            {loading ? (
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            ) : (
                <InfiniteScroll
                    dataLength={leaderboard.length}
                    hasMore={hasMore}
                    next={fetchNextOverviewData}
                    loader={<TableSkeleton />}
                >
                    {(parseInt(episode) >= league.episode_started ||
                        episode === "0") && (
                        <Table>
                            <thead>
                                <tr className="header">
                                    <th>Rank</th>
                                    <th>Team Name</th>
                                    <th className="text-right pr-5">Points</th>
                                    {leagueUser &&
                                        leagueUser.commissioner &&
                                        league.active && <th />}
                                </tr>
                            </thead>
                            <tbody>{renderTableBody}</tbody>
                        </Table>
                    )}
                </InfiniteScroll>
            )}
            <AreYouSureModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                body={`Are you sure you want to remove <b>${
                    selectedTeam && selectedTeam.team_name
                }</b> from your league?`}
                onSuccess={() => handleDeleteTeam()}
                submitting={deleting}
                submitText={"Removing"}
            />
            <ViewProfile
                showModal={showProfile}
                setShowModal={setShowProfile}
                userId={profileId}
            />
        </Well>
    );
};

export default Overview;
