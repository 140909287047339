import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Form, Row, Button } from "react-bootstrap";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";
import { League } from "../../models/Leagues";
import { Show } from "../../models/Shows";
import { LeagueChallengeQuestions } from "../../models/ChallengeQuestions";
import { QuestionAnswerCast } from "../../models/Questions";

import PageTemplate from "../templates/PageTemplate";
import { TableSkeleton } from "../atoms/Skeletons";
import LogScoresEpisodeSelect from "../atoms/LogScoresEpisodeSelect";
import SubmitButton from "../atoms/SubmitButton";
import LogAnswers from "../molecules/LogAnswers";

interface ParamTypes {
    leagueId: string;
    episodeParam?: string;
}

export interface AnswerValues {
    answers: {
        question_id: number;
        cast: QuestionAnswerCast[];
    }[];
}

const LogCustomAnswers: React.FC = () => {
    const history = useHistory();
    const { leagueId, episodeParam } = useParams<ParamTypes>();
    const episode = episodeParam ? parseInt(episodeParam) : 0;
    const [show, setShow] = useState<Show>();
    const [cast, setCast] = useState<Cast[]>([]);
    const [league, setLeague] = useState<League>();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [questions, setQuestions] = useState<LeagueChallengeQuestions[]>([]);
    const [answers, setAnswers] = useState<
        { question_id: number; cast: QuestionAnswerCast[] }[]
    >([]);

    const loggedAnswersRequest = useApi(
        apiRoutes.GET_CUSTOM_LOGGED_ANSWERS(leagueId),
        {
            onSuccess: (response: {
                questions: LeagueChallengeQuestions[];
                cast: Cast[];
                league: League;
                show: Show;
            }) => {
                const formattedAnswers = [];

                for (let i = 0; i < response.questions.length; i++) {
                    const formatCast = response.questions[i].answers.map(
                        (c) => {
                            return {
                                ...c,
                                name: `${c.first_name} ${c.last_name}`,
                            };
                        }
                    );
                    formattedAnswers.push({
                        question_id: response.questions[i].question_id,
                        cast: formatCast,
                    });
                }

                setCast(response.cast);
                setAnswers(formattedAnswers);
                setShow(response.show);
                setLeague(response.league);
                setQuestions(response.questions);
                setLoading(false);
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );

    const saveLoggedQuestions = useApi(
        apiRoutes.SAVE_CUSTOM_LOGGED_QUESTIONS(leagueId, episode.toString()),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                toast.success(message);
                setSubmitting(false);
                history.push(`/myleagues/view/${leagueId}`);
            },
            onFailure: () => {
                setSubmitting(false);
            },
        }
    );

    useEffect(() => {
        loggedAnswersRequest.request({ episode });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode]);

    const handleSubmit = (
        results: {
            question_id: number;
            cast: QuestionAnswerCast[];
        }[]
    ) => {
        setSubmitting(true);
        saveLoggedQuestions.request({ answers: results });
    };

    return (
        <PageTemplate
            header="Log Custom Challenge Questions"
            headerSecondary={league && league.name}
            loading={loading}
            skeleton={
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            }
        >
            {show && (
                <>
                    <LogScoresEpisodeSelect
                        customAnswers
                        leagueId={leagueId}
                        show={show}
                        episode={episode}
                    />
                    {episode > 0 && questions.length > 0 && (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(answers);
                            }}
                        >
                            <LogAnswers
                                answers={answers}
                                questions={questions}
                                cast={cast}
                                setValues={(
                                    list: QuestionAnswerCast[],
                                    questionId: number
                                ) => {
                                    const newAnswers = answers.map((a) =>
                                        a.question_id === questionId
                                            ? {
                                                  ...a,
                                                  cast: list,
                                              }
                                            : { ...a }
                                    );
                                    setAnswers(newAnswers);
                                }}
                            />
                            <Row className="mt-3 justify-content-center">
                                <SubmitButton
                                    className="ml-3 mr-2"
                                    title="Submit"
                                    submitting={submitting}
                                />
                                <Button
                                    variant="light"
                                    onClick={() =>
                                        history.push(
                                            `/myleagues/view/${leagueId}`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Row>
                        </Form>
                    )}
                </>
            )}
        </PageTemplate>
    );
};

export default LogCustomAnswers;
