import React from "react";
import { useHistory } from "react-router";

import { CurrentLeagues } from "../../models/Leagues";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Col, Row } from "react-bootstrap";

import { MyleaguesListItem } from "../organisms/styles/MyleaguesList.styled";
import LeagueBadge from "../atoms/LeagueBadge";
import CastImage from "../atoms/CastImage";

interface MyleaguesRowProps {
    league: CurrentLeagues;
}

const MyleaguesRow: React.FC<MyleaguesRowProps> = ({ league }) => {
    const history = useHistory();

    return (
        <MyleaguesListItem
            key={league.id}
            onClick={() =>
                history.push(
                    `/myleagues/view/${league.id}${
                        league.id === "0" ||
                        league.id === 0 ||
                        league.id === "team" ||
                        league.id === "budget"
                            ? `/${league.show_id}`
                            : ""
                    }`
                )
            }
        >
            <LeagueBadge league={league} />
            <Col>
                <Row>
                    {league.img && (
                        <div className="mr-3">
                            <CastImage src={league.img} />
                        </div>
                    )}
                    <div>
                        <div className="font-weight-bold">{league.name}</div>
                        <div
                            className="d-flex align-items-center"
                            style={{ fontSize: 14 }}
                        >
                            Rank:{" "}
                            {league.live ? (
                                <RadioButtonCheckedIcon
                                    className="ml-1 pulse"
                                    fontSize="small"
                                    color="error"
                                />
                            ) : (
                                <>
                                    {league.rank} / {league.total}
                                </>
                            )}
                        </div>
                        <div style={{ fontSize: 14 }}>
                            Draft Type: {league.draft_type}
                        </div>
                    </div>
                </Row>
            </Col>
            <Col className="text-right">
                {league.team_name}{" "}
                <ArrowForwardIcon
                    style={{
                        cursor: "pointer",
                        marginLeft: 3,
                    }}
                    fontSize="medium"
                />
            </Col>
        </MyleaguesListItem>
    );
};

export default MyleaguesRow;
