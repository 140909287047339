import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LoggedScoresReturn } from "../../models/ScoringLog";
import useQuery from "../../hooks/useQuery";
import { RootState } from "../../store";
import { login } from "../../store/user/actions";
import { UserModel } from "../../models/User";
import { Subscription } from "../../models/Subscriptions";

import Form from "react-bootstrap/Form";

import { TableSkeleton } from "../atoms/Skeletons";
import SubmitButton from "../atoms/SubmitButton";
import LogScoresEpisodeSelect from "../atoms/LogScoresEpisodeSelect";
import LogScoresAccordion from "../molecules/LogScoresAccordion";
import PageTemplate from "../templates/PageTemplate";

const validationSchema = yup.object({
    log: yup.array().of(
        yup.object().shape({
            id: yup.number(),
            name: yup.string(),
            eliminated: yup.boolean(),
            scores: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    description: yup.string(),
                    amount: yup.number(),
                    value: yup.number(),
                })
            ),
        })
    ),
});

interface ParamTypes {
    leagueId: string;
    episodeParam?: string;
}

const CustomLogScores: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { leagueId, episodeParam } = useParams<ParamTypes>();
    const query = useQuery();
    const userToken = query.get("token") ? query.get("token") : "";
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

    const [show, setShow] = useState<Show>();
    const [loading, setLoading] = useState<boolean>(true);
    const [log, setLog] = useState<LoggedScoresReturn[]>();
    const episode = episodeParam ? parseInt(episodeParam) : 0;

    //could send through type to see if we need to grab custom or regular
    const [submitting, setSubmitting] = useState<boolean>(false);

    const loggedScoresRequest = useApi(
        apiRoutes.GET_CUSTOM_LOGGED_SCORES(leagueId),
        {
            onSuccess: (response: {
                cast: LoggedScoresReturn[];
                show: Show;
            }) => {
                setLog(response.cast);
                setShow(response.show);
                setLoading(false);
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );
    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(leagueId), {
        responseKey: "show",
        onSuccess: (response: Show) => {
            setShow(response);
            setLoading(false);
        },
        onFailure: (message: string) => {
            setLoading(false);
        },
    });
    const saveLoggedScoresRequest = useApi(
        apiRoutes.SAVE_CUSTOM_LOGGED_SCORES(leagueId),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                toast.success(message);
                setSubmitting(false);
                history.push(`/myleagues/view/${leagueId}`);
            },
            onFailure: () => {
                setSubmitting(false);
            },
        }
    );
    const getUserByTokenRequest = useApi(apiRoutes.GET_USER_BY_TOKEN(), {
        onSuccess: (response: {
            user: UserModel;
            subscription: Subscription;
        }) => {
            dispatch(login({ ...response.user, isLoggedIn: true }));
        },
    });

    useEffect(() => {
        if (isLoggedIn) {
            if (episode > 0) {
                loggedScoresRequest.request({ episode });
            } else {
                leagueRequest.request();
            }
        } else {
            if (userToken.length > 0) {
                getUserByTokenRequest.request({ token: userToken });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode, isLoggedIn]);

    const initialValues = {
        log: log,
    };

    return (
        <PageTemplate
            header="Log Custom Scores"
            loading={loading}
            skeleton={
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            }
        >
            {show && (
                <>
                    <LogScoresEpisodeSelect
                        leagueId={leagueId}
                        show={show}
                        episode={episode}
                    />
                    {episode > 0 && (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                setSubmitting(true);
                                saveLoggedScoresRequest.request({
                                    data: values.log,
                                    episode: episode,
                                });
                            }}
                        >
                            {({ values, handleSubmit, isValid }) => {
                                return (
                                    <Form
                                        onSubmit={(e) => {
                                            if (!isValid) {
                                                //toast.error("Please fix the errors");
                                            }
                                            handleSubmit(e);
                                        }}
                                    >
                                        <LogScoresAccordion
                                            custom
                                            values={values}
                                            log={log}
                                        />
                                        <div className="text-center">
                                            <SubmitButton
                                                title="Submit Scores"
                                                submitting={submitting}
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </>
            )}
        </PageTemplate>
    );
};

export default CustomLogScores;
