import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

import List from "@mui/material/List";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemText from "@mui/material/ListItemText";
import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { TeamLeagueTeams } from "../../models/TeamLeagueTeams";
import { Show } from "../../models/Shows";
import { League } from "../../models/Leagues";

import TeamLineupListItem from "../molecules/TeamLineupListItem";
import { Body, Footer, Header, ListHeader } from "./styles/SetTeam.styled";
import AnalyticsSelect from "../atoms/AnalyticsSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { TableSkeleton } from "../atoms/Skeletons";
import AreYouSureModal from "../atoms/AreYouSureModal";
import LineupHeader from "../molecules/LineupHeader";
import { handleSortChange } from "../../helpers/analyticsSorter";

interface SetTeamProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    league: League;
    leagueUser: LeaguesLeagueUser;
    locked?: boolean;
    show: Show;
}

const SetTeam: React.FC<SetTeamProps> = ({
    open,
    setOpen,
    league,
    leagueUser,
    locked,
    show,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);
    const [episode, setEpisode] = useState<number>(show.current_episode);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [starters, setStarters] = useState<TeamLeagueTeams[]>([]);
    const [bench, setBench] = useState<TeamLeagueTeams[]>([]);
    const [starterMovingId, setStarterMovingId] = useState<number>(0);
    const [benchMovingId, setBenchMovingId] = useState<number>(0);
    const [sort, setSort] = useState<string>("name");
    const [showModal, setShowModal] = useState<boolean>(false);

    const lineupRequest = useApi(
        apiRoutes.GET_TEAM_LINEUP(leagueUser.id.toString()),
        {
            onSuccess: (response: {
                starters: TeamLeagueTeams[];
                bench: TeamLeagueTeams[];
                show: Show;
            }) => {
                setStarters(response.starters);
                setBench(response.bench);
                setEpisode(show.current_episode);
                setLoading(false);
            },
            onFailure: () => setLoading(false),
        }
    );
    const saveRequest = useApi(
        apiRoutes.SAVE_TEAM_LINEUP(leagueUser.id.toString()),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                toast.success(response);
                setSubmitting(false);
                closePanel();
            },
            onFailure: () => setSubmitting(false),
        }
    );

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
            lineupRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, show]);

    const closeWithoutSave = () => {
        document.body.style.overflow = "auto";
        if (show.locked) {
            closePanel();
        } else {
            setShowModal(true);
        }
    };

    const closePanel = () => {
        setShowModal(false);
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    const swapStarter = (id: number) => {
        const benchArrary = [...bench];
        const starterArray = [...starters];

        const starterIndex = starters.findIndex((starter) => starter.id === id);
        const benchIndex = bench.findIndex(
            (bench) => bench.id === benchMovingId
        );
        const starter = { ...starters[starterIndex] };
        const benchPlayer = { ...bench[benchIndex] };

        benchArrary[benchIndex] = starter;
        starterArray[starterIndex] = benchPlayer;

        setStarters(starterArray);
        setBench(benchArrary);
        setStarterMovingId(0);
        setBenchMovingId(0);
    };

    const swapBench = (id: number) => {
        const benchArrary = [...bench];
        const starterArray = [...starters];

        const starterIndex = starters.findIndex(
            (starter) => starter.id === starterMovingId
        );
        const benchIndex = bench.findIndex((bench) => bench.id === id);
        const starter = { ...starters[starterIndex] };
        const benchPlayer = { ...bench[benchIndex] };

        benchArrary[benchIndex] = starter;
        starterArray[starterIndex] = benchPlayer;

        setStarters(starterArray);
        setBench(benchArrary);
        setStarterMovingId(0);
        setBenchMovingId(0);
    };

    const saveTeamLineup = () => {
        setSubmitting(true);
        saveRequest.request({ starters, bench });
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={closeWithoutSave}
        >
            <div className="overflow-hidden">
                <Header style={isMobile ? { height: 100 } : {}}>
                    <h5>
                        Set Lineup <small>{leagueUser.team_name}</small>{" "}
                        <small>
                            {show.type === "week" ? "Week" : "Episode"}{" "}
                            {episode}
                        </small>
                    </h5>
                    {isMobile && (
                        <>
                            <Button
                                disabled={
                                    submitting ||
                                    locked ||
                                    show.current_episode > show.episodes ||
                                    show.current_episode <
                                        league.episode_started
                                }
                                variant="success"
                                onClick={() => saveTeamLineup()}
                            >
                                {submitting ? (
                                    <>
                                        Saving{" "}
                                        <CircularProgress
                                            size={12}
                                            color="inherit"
                                        />
                                    </>
                                ) : (
                                    "Save Team"
                                )}
                            </Button>{" "}
                            <Button variant="light" onClick={closeWithoutSave}>
                                Cancel
                            </Button>
                        </>
                    )}
                </Header>
                <Body
                    style={
                        isMobile
                            ? {
                                  height: "calc(100% - 100px)",
                                  top: 100,
                                  paddingBottom: 100,
                                  borderBottomRightRadius: 20,
                              }
                            : {}
                    }
                >
                    {!loading ? (
                        <List>
                            <LineupHeader show={show} league={league} />
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <div className="ml-3 mr-3">
                                        <AnalyticsSelect
                                            secondary="Starting (site wide)"
                                            tertiary="Starting (this league)"
                                            fourth="Average Score"
                                            value={sort}
                                            onChange={(value: string) => {
                                                setSort(value);
                                                setStarterMovingId(0);
                                                setBenchMovingId(0);
                                                handleSortChange(
                                                    value,
                                                    starters,
                                                    setStarters,
                                                    "team"
                                                );
                                                handleSortChange(
                                                    value,
                                                    bench,
                                                    setBench,
                                                    "team"
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            <ListHeader divider>
                                <ListItemText primary="Starters" />
                            </ListHeader>
                            {starters.map((player: TeamLeagueTeams) => {
                                return (
                                    <TeamLineupListItem
                                        key={player.id}
                                        player={player}
                                        swap={swapStarter}
                                        setMovingId={setStarterMovingId}
                                        mainMovingId={benchMovingId}
                                        secondaryMovingId={starterMovingId}
                                        currentEpisode={episode}
                                        locked={
                                            show.locked ||
                                            show.current_episode <
                                                league.episode_started
                                        }
                                    />
                                );
                            })}
                            <ListHeader divider>
                                <ListItemText primary="Bench" />
                            </ListHeader>
                            {bench.map((player: TeamLeagueTeams) => {
                                return (
                                    <TeamLineupListItem
                                        key={player.id}
                                        player={player}
                                        swap={swapBench}
                                        setMovingId={setBenchMovingId}
                                        mainMovingId={starterMovingId}
                                        secondaryMovingId={benchMovingId}
                                        currentEpisode={episode}
                                        locked={
                                            show.locked ||
                                            show.current_episode <
                                                league.episode_started
                                        }
                                    />
                                );
                            })}
                        </List>
                    ) : (
                        <>
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                            <TableSkeleton />
                        </>
                    )}
                </Body>
                {!isMobile && (
                    <Footer>
                        <Button
                            disabled={
                                submitting ||
                                locked ||
                                show.current_episode > show.episodes ||
                                show.current_episode < league.episode_started
                            }
                            variant="success"
                            onClick={() => saveTeamLineup()}
                        >
                            {submitting ? (
                                <>
                                    Saving{" "}
                                    <CircularProgress
                                        size={12}
                                        color="inherit"
                                    />
                                </>
                            ) : (
                                "Save Team"
                            )}
                        </Button>{" "}
                        <Button variant="light" onClick={closeWithoutSave}>
                            Cancel
                        </Button>
                    </Footer>
                )}
                <AreYouSureModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    title={`Are you sure you want to exit set lineup?`}
                    body={`Your changes have not been saved.`}
                    onSuccess={() => closePanel()}
                />
            </div>
        </SlidingPanel>
    );
};

export default SetTeam;
